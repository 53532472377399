import cx from "classnames";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import _debounce from "lodash/debounce";

import { VideoMarker } from "@/components/VideoMarker/VideoMarker";
import { useMeasurement } from "@/hooks/measurement.hook";
import { useQuestionnaire } from "@/hooks/questionnaire.hook";
import { BaseLayout } from "@/components/Layout/BaseLayout";
import { Button, ButtonType } from "@/components/Button/Button";
import { MeasurementButton, MeasurementButtonTheme } from "@/components/MeasurementButton/MeasurementButton";

import "./Measurement.scss";
import { AppModal } from "@/components/Modal/Modal";
import { useAppSelector } from "@/hooks";
import { ReactComponent as LogoWhite } from "@/images/logo-white.svg";
import { shallowEqual } from "react-redux";
import { ReactComponent as IcRing } from "@/images/ic-ring.svg";
import { useTheme } from "@/context/theme";
import { IconQuestion } from "@/components/Icons";
import { BackButton } from "@/components/BackButton/BackButton";
import { LiveResults, QuestionItem } from "intelliprove-streaming-sdk";
import { LiveBiometric } from "@/components/LiveBiometric/LiveBiometric";
import { MeasurementState } from "@/utils/intelliprove.service";
import { debugLog } from "@/utils/helper";
import { messageService } from "@/utils/messaging.service";
import { QuestionModal } from "@/components/QuestionModal/QuestionModal";
import { CameraSelector } from "@/components/CameraSelector/CameraSelector";

export const MeasurementPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const { t } = useTranslation();
  const [darkMode, setDarkMode] = useState(true);
  const [torchEnabled, setTorchEnabled] = useState(false);
  const [setting] = useAppSelector((state) => [state.setting], shallowEqual);
  const {
    videoLoading,
    timeLeft,
    qualityResponse,
    startMeasurement,
    changeCamera,
    toggleTorch,
    getStatusMessage,
    status,
    error,
    finished,
    stopMeasurement,
    liveResult,
    loading,
    liveResultError,
    availableCameras,
    flippedCamera,
    activateCameraIndex,
  } = useMeasurement();
  const onStartMeasurement = useCallback(_debounce(startMeasurement, 250), []);
  const [showCameraSelector, setShowCameraSelector] = useState(false);
  const statusRef = useRef(status);

  const { activeQuestion, questionAnswerCallback, questionDiscardCallback, nextQuestion, getQuestion } = useQuestionnaire({
    questions: setting.questionnaireQuestions,
  });

  const [questionShown, setQuestionShown] = useState(false);
  const [question, setQuestion] = useState<QuestionItem | null>(null);
  const questionnaireInterval = 2000;
  const initialQuestionDelay = 1000;

  const measurementProgress =
    status === MeasurementState.Measurement ? ((setting.duration! - (timeLeft ?? setting.duration!)) / setting.duration!) * 100 : 100;

  useEffect(() => {
    statusRef.current = status;
  }, [status]);

  useEffect(() => {
    // Function to be executed when tab visibility changes
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        if (statusRef.current === MeasurementState.Measurement || statusRef.current === MeasurementState.QualityCheck) {
          stopMeasurement("tab out of focus");
        }
      }
    };

    // Set up the event listener
    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  });

  const __getMarkerColor = () => {
    let errorColor = theme.value.functional_primary_500;
    let successColor = theme.value.functional_tertiary_500;
    let neutralColor = theme.value.brand_primary;

    if (liveResultError) {
      return liveResultError.error_code !== 0 ? errorColor : neutralColor;
    }
    if (qualityResponse) {
      return qualityResponse.error_code !== 0 ? errorColor : successColor;
    }
    return darkMode ? neutralColor : "#FFFFFF";
  };

  useEffect(() => {
    if (!flippedCamera && !darkMode) {
      __toggleDarkMode();
    }

    if (flippedCamera && torchEnabled) {
      // the actual torch gets turned of on its own if you remove the media track
      setTorchEnabled(false);
    }
  }, [flippedCamera]);

  const __getOverlayStyle = useCallback(() => {
    // if ([MeasurementState.Measurement].includes(status)) {
    //   return "linear-gradient(to top, rgba(var(--rgb-functional-tertiary-500), .5), 35%, rgba(255, 255, 255, 0))";
    // }

    if (__showBannerError()) {
      return "linear-gradient(to top, rgba(var(--rgb-functional-primary-500), .5), 35%, rgba(255, 255, 255, 0))";
    }

    if (!darkMode) {
      return "linear-gradient(to top, rgba(255, 255, 255, 1), 10%, rgba(255, 255, 255, .5), 35%, rgba(255, 255, 255, 0))";
    }

    return `none`;
  }, [qualityResponse?.error_code, darkMode, liveResultError?.error_code]);

  const __getButtonTheme = () => {
    if (__showBannerError()) {
      return MeasurementButtonTheme.error;
    }

    switch (status) {
      case MeasurementState.QualityCheck:
        return MeasurementButtonTheme.starting;
      case MeasurementState.Measurement:
        return MeasurementButtonTheme.measuring;
      default:
        return MeasurementButtonTheme.default;
    }
  };

  const __toggleDarkMode = () => {
    setDarkMode((val) => !val);
    document.documentElement.style.setProperty("--color-support-100", darkMode ? "#c3c5ce" : theme.value.support_100 || "#FAF9F7");
  };

  const __toggleFlash = () => {
    if ([MeasurementState.Measurement].includes(status)) {
      return;
    }

    if (flippedCamera) {
      __toggleDarkMode();
    } else {
      if (toggleTorch(!torchEnabled)) {
        setTorchEnabled(!torchEnabled);
      }
    }
  };

  const __swapCameras = () => {
    if (status != MeasurementState.Idle) {
      return;
    }

    if (availableCameras.length === 1) {
      return;
    } else if (availableCameras.length === 2) {
      changeCamera();
    } else {
      setShowCameraSelector(!showCameraSelector);
    }
  };

  const __changeCamera = (d: MediaDeviceInfo) => {
    changeCamera(d.deviceId);
    setShowCameraSelector(false);
  };

  const __showBannerError = useCallback(() => {
    const error = qualityResponse ?? liveResultError;

    if (error) {
      return error?.error_code !== 0;
    }

    return false;
  }, [liveResultError?.error_code, qualityResponse?.error_code]);

  const __notification = () => {
    return (
      <div className="flex flex-col">
        {/* render condition for mobile */}
        {!questionShown && (
          <div
            className={cx(
              "w-full sm:w-[unset] sm:hidden",
              "text-center font-semibold text-lg measurement-status-wrapper",
              status == MeasurementState.QualityCheck || (status == MeasurementState.Measurement && qualityResponse?.error_code !== 0)
                ? "block"
                : "hidden",
              {
                "error uppercase": __showBannerError() && qualityResponse?.error_code !== 0,
                "dark-mode": theme.value.dark_mode,
              },
            )}
          >
            {status === MeasurementState.Measurement && qualityResponse?.error_code === 0 && (
              <div
                className={cx(
                  "block sm:hidden h-0 w-0 border-x-8 border-x-transparent border-t-[16px] measurement-status-wrapper-arrow  absolute bottom-[-16px] left-[calc(50%-10px)]",
                )}
              />
            )}

            <span>{getStatusMessage()}</span>
          </div>
        )}

        {/* render condition for desktop */}
        {[MeasurementState.Measurement, MeasurementState.QualityCheck].includes(status) && (
          <div
            className={cx("hidden sm:block w-full sm:w-[unset]", "text-center font-semibold text-lg measurement-status-wrapper", {
              "error uppercase": __showBannerError(),
              "dark-mode": theme.value.dark_mode,
            })}
          >
            <span>{getStatusMessage()}</span>
          </div>
        )}
      </div>
    );
  };

  const __backBtn = () => {
    if (!__showBackButton()) {
      return null;
    }

    return (
      <BackButton
        darkMode={darkMode}
        type={darkMode ? ButtonType.Dark : ButtonType.White}
        onClick={async () => {
          await stopMeasurement("Return");
          if (setting.pluginSettings?.embedded_app && setting.pluginSettings?.skip_welcome) {
            messageService.dismiss();
          } else {
            setTimeout(() => navigate(-1));
          }
        }}
        className="uppercase"
      />
    );
  };

  const __showBackButton = () => {
    if (setting.pluginSettings?.embedded_app) {
      return true;
    } else if (setting.pluginSettings?.embedded_iframe) {
      return false;
    }
    return !setting.pluginSettings?.skip_welcome;
  };

  const __header = () => {
    if (finished) return null;

    return (
      <div className="flex justify-between items-center">
        <div className="sm:w-1/6 flex ">{__backBtn()}</div>

        <div className="hidden sm:flex flex-col absolute sm:relative top-[80px] sm:top-[unset]">{__notification()}</div>

        <div className="sm:w-[52px] md:w-1/6 flex justify-end">
          <Button
            type={darkMode ? ButtonType.Dark : ButtonType.White}
            content={<IconQuestion darkMode={darkMode} />}
            onClick={async () => {
              await stopMeasurement("Onboarding");
              setTimeout(() => navigate("/measurement/onboarding", { state: { from: location.pathname } }));
            }}
            className="w-[38px] h-[38px] sm:w-[40px] sm:h-[40px] rounded-full px-0 min-h-[38px] sm:min-h-[40px]"
          />
        </div>
      </div>
    );
  };

  const renderMotivationMessage = () => (
    <>
      {/* if mobile render message */}
      {qualityResponse?.error_code === 0 && !questionShown && (
        <div className="flex flex-col sm:hidden">
          <div className="w-full sm:w-[unset] text-center font-semibold text-lg measurement-status-wrapper mb-3">
            <span>{t("motivation_1", "You're doing good, please hold still")}</span>
          </div>
        </div>
      )}
      {/* if desktop render biomarker */}
      <div className="hidden sm:flex flex-col gap-[16px]">{renderLiveFeedback()}</div>
    </>
  );

  const renderLiveFeedback = () => {
    if (liveResult) {
      return <LiveBiometric result={liveResult as LiveResults} />;
    }

    return null;
  };

  useEffect(() => {
    if (status === MeasurementState.Measurement) {
      setQuestionShown(true);
      setTimeout(() => nextQuestion(), initialQuestionDelay);
    } else {
      setQuestionShown(false);
    }
  }, [status]);

  useEffect(() => {
    setQuestion(getQuestion());

    if (questionShown && activeQuestion === -1) {
      setTimeout(() => nextQuestion(), questionnaireInterval);
    }
  }, [activeQuestion]);

  useEffect(() => {
    if (liveResultError && liveResultError.error_code !== 0) {
      setQuestionShown(false);
    } else {
      setQuestionShown(true);
    }
  }, [liveResultError]);

  // useEffect(() => {
  //   // Add a delay of 3000 milliseconds (3 seconds) before loading the video frame
  //   const delay = 3000;
  //   const timeoutId = setTimeout(() => {
  //     setLoadVideo(false);
  //   }, delay);

  //   // Cleanup function to clear the timeout in case the component unmounts
  //   return () => clearTimeout(timeoutId);
  // }, []); // Empty dependency array to ensure the effect runs only once on mount

  return (
    <BaseLayout
      containerClass={cx({
        "bg-[#000]": darkMode,
      })}
    >
      <div className="flex w-full h-[calc(100dvh)] items-center justify-center relative" id="measurementScreen">
        <div className="absolute top-[24px] left-[24px] z-50" style={{ width: "calc(100% - 48px)" }}>
          {__header()}
        </div>

        <div className="w-screen h-screen relative flex items-center justify-center">
          {videoLoading && (
            <div className="ring absolute h-full w-full flex bg-black z-10">
              <IcRing className="items-center w-full m-auto" />
            </div>
          )}
          <video
            ref={videoRef}
            id="video"
            className={cx({ vblur: finished }, { "video-flipped": flippedCamera })}
            autoPlay
            loop
            muted
            playsInline
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              ...(!darkMode
                ? {
                    border: "solid 10px #fff",
                    boxShadow: "inset 0 0 10vw 2.5vw white",
                  }
                : {}),
              ...(!darkMode ? { borderRadius: "30px" } : {}),
            }}
          />
          {/* The overlay */}
          <div
            className="w-full h-full absolute top-0 bottom-0"
            style={{
              backgroundImage: __getOverlayStyle(),
              backgroundSize: "auto 100%",
              backgroundRepeat: "repeat-x",
              backgroundPosition: "left bottom",
            }}
          />
        </div>

        {!finished && (
          <div className="absolute top-0 h-full w-full px-[25px] pt-[64px] pb-[113px]">
            <VideoMarker color={__getMarkerColor()} />
          </div>
        )}
        {/* {status === MeasurementState.Measurement && ( */}
        <div
          className={cx(
            "absolute flex gap-[4px] sm:gap-[16px] justify-center",
            // responsive
            "w-full h-[unset] sm:h-full sm:w-[unset]",
            "bottom-[123px] top-[unset] px-[25px] sm:top-0 sm:bottom-[unset] sm:px-[unset]",
            "flex-row sm:flex-col",
            "right-[unset] sm:right-[25px]",
          )}
        >
          {renderMotivationMessage()}
        </div>
        {/* )} */}

        <div className="absolute bottom-[24px] left-[24px] z-50" style={{ width: "calc(100% - 48px)" }}>
          <div className="absolute sm:hidden bottom-[109px] left-1/2 transform -translate-x-1/2 w-full">{__notification()}</div>

          <div className="flex flex-col justify-between lg:flex-row">
            <div className="w-1/6 items-end hidden lg:flex">
              {!setting.pluginSettings?.hide_watermark && <LogoWhite className="opacity-50 h-auto" />}
            </div>

            <div>
              {question && questionShown && (
                <div className="flex justify-center">
                  <QuestionModal question={question} answerCallback={questionAnswerCallback} discardedCallback={questionDiscardCallback} />
                </div>
              )}

              <div className="w-full flex justify-center pb-4">
                {showCameraSelector && (
                  <CameraSelector
                    darkMode={darkMode}
                    devices={availableCameras}
                    selectedDevice={activateCameraIndex}
                    deviceSelected={(d) => __changeCamera(d)}
                  />
                )}
              </div>

              {!finished && (
                <MeasurementButton
                  // disabled={status === MeasurementState.QualityCheck}
                  darkMode={darkMode}
                  torchOn={torchEnabled}
                  theme={__getButtonTheme()}
                  timeLeft={timeLeft ?? setting.duration!}
                  onStart={() => {
                    if (status == MeasurementState.Abort) {
                      debugLog("start/stop measurement stopped due to status: ", status);
                      return;
                    }

                    onStartMeasurement();
                  }}
                  toggleFlash={__toggleFlash}
                  showCameraSwap={availableCameras.length > 1}
                  fillCameraIcon={showCameraSelector}
                  swapCameras={__swapCameras}
                  processing={status == MeasurementState.Measurement}
                  loading={
                    status == MeasurementState.Abort ||
                    (timeLeft ?? 101) < 100 ||
                    loading ||
                    (MeasurementState.QualityCheck === status && qualityResponse === null)
                  }
                  progress={measurementProgress}
                />
              )}
            </div>

            <div className="w-1/6 hidden lg:flex"></div>
          </div>
        </div>
      </div>

      <AppModal
        isOpen={error?.isOpen}
        title={error?.title}
        text={error?.text}
        onDismiss={error?.onDismiss ? () => error?.onDismiss?.() : undefined}
        dismissable={error?.dismissable}
        action={error?.action}
        secondaryAction={error?.secondaryAction}
        icon={error?.icon}
      />
    </BaseLayout>
  );
};
